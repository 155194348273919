.support {
    section.contact-section {
        text-align: center;
    }
    h1{
        color:white;
        margin:3em 0;
    }
    h3{
        text-align: center;
    }
    section .img-responsive {
        margin-right:auto;
        margin-left:auto;
    }
    .hero-unit{
        margin: 55px 0 2em;
        padding:0;
        background:url("/assets/img/maco_americas_chicago.jpg") no-repeat 0;
        background-size:cover;
    }
    section .contactnames p{
        margin: 0 0 0.25em;
    }
}   
