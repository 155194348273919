/* Header */
.navbar {
    min-height: 55px;
    margin-bottom: 0;
    font-size:16px;
    letter-spacing: 1px;
    font-weight:200;
    border:none;
} 
.header--on-scroll{
    box-shadow: 0 1px 20px 3px rgba(0, 0, 0, 0.1);
}
.navbar-default {
    background-color: white;
    border-color: $cbre-light-grey;
}
.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border:none;
    text-align: center;
} 
.navbar-brand {
    padding: 15px 20px;
    line-height: 100%;
    height: auto;
}
.navbar-default .white-logo{
    display:none;
} 
.navbar-default .navbar-nav > li > a {
    padding: 15px 0;
    color: $cbre-dark-grey;
    font-weight: 500;
    border-bottom: 1px solid $cbre-lightmid-grey;
    margin: 0 20px;
}
.navbar-default .navbar-nav > .open > a{
    background-color: transparent;
    color: $cbre-green;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > .open > a:hover, 
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > li > a:hover .caret,
.navbar-default .navbar-nav > li > a:focus .caret,
.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
.navbar-default .navbar-nav .open .dropdown-menu > li > a:focus{
    background-color: transparent;
    color: $cbre-link;
}
.navbar-nav .open .dropdown-menu > li > a, 
.navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 8px 15px 8px 25px;
}
.navbar-nav {
    margin: 1em -15px 9em;
}
ul.navbar-nav li {
    margin: 0;
    text-align: left;
}
ul.navbar-nav li.tablet-hide {
    display:none;
}
.navbar-nav > li > .dropdown-menu {
    padding: 10px 15px;
}
.navbar-nav .open ul.dropdown-menu li {
    margin: 0;
}
.dropdown-menu > li > a {
    padding: 8px;
    color: $cbre-grey;
}
.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    color: $cbre-green;
}
.navbar-brand > img {
    width:100%;
}
.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: $cbre-light-grey;
}
.navbar-toggle {
    border: none;
    margin-top:3px;
    margin-right: 10px;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: transparent;
}
/* Menu dropdown */
.navbar-default .navbar-toggle .icon-bar {
    width: 30px;
    height: 3px;
    border-radius: 20px; 
    margin-top: 5px;
    background-color: $cbre-dark-grey;
}
.navbar-default .navbar-toggle.collapsed .icon-bar {
    width: 25px;
}
.caret {
    border-top: 6px dashed;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    color:$cbre-grey;
}
.navbar-collapse.collapse{
    transition: height 0.1s ;
}
.navbar-collapse.collapsing {
    height: 0 !important;
}
.navbar-collapse.collapse.in{
    max-height: none;
    height: 100vh;
}
.navbar-toggle {
  border: none;
  background: transparent !important;
  &:hover {
    background: transparent !important;
  }
  .icon-bar {
    width: 22px;
    transition: all 0.2s;
  }
  .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
  }
  .middle-bar {
    opacity: 0;
  }
  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
  }
  &.collapsed {
    .top-bar {
      transform: rotate(0);      
    }
    .middle-bar {
      opacity: 1;
    }
    .bottom-bar {
      transform: rotate(0);
    }
  }
}
.navbar-nav .dropdown-menu {
    display: block;
    text-align: center;
    padding: 8px 0;
    background: $cbre-light-grey;
    position: relative;
    top: 0;
    left: 0;
    float: none;
    border: none;
    box-shadow: none;
}
/* Get Demo */
.navbar .btn-default, .navbar .btn-default-alt {
    margin: 2em auto 0;
    font-size:14px;
} 
.navbar-default .navbar-nav > li > a.btn-default-alt{
    color:$cbre-blue;
}
.navbar-default .navbar-nav .btn-default-alt:hover {
    color:white;
    background-color:$cbre-blue;
} 
.navbar-default .navbar-nav > li > a.btn-default {
    color: #fff;
}
.navbar-default .navbar-nav > li > a.btn-default:hover {
    color: $cbre-green;
}
@media (min-width: 768px){  
    .navbar-right {
        margin: 0.65em 0 0 0;
    }
    ul.navbar-nav li {
        margin: 0;
    }
    .navbar > .container .navbar-brand {
        padding: 1em 0;
        margin:0;
    }
    .navbar-default .navbar-nav > li > a {
        padding: 0.75em 0; 
        border:none;
        font-weight: 500;
        margin:0 8px;
        font-size:14px
    }
    .navbar-default .navbar-nav > li > a.highlight {
        border:1px solid $cbre-green;
        color: white;
        border-radius:3px;
        width: 100%;
    }
    .navbar-default .navbar-nav > li > a.bluelight {
        border-radius:3px;
        width: 100%;
    }
    .navbar-default .navbar-nav > li > a.highlight:hover {
        color: $cbre-link;
    }
    .caret {
        border-top: 5px dashed;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
    }
    /* Get Demo */
    .navbar .btn-default{
        margin: 0;
    } 
    .navbar .btn-default-alt {
        margin: 0 0 0 1em;
    } 
}
@media (min-width: 992px) {
    .navbar-right {
        margin: 1em 0 0 0;
    }
    .navbar-default .navbar-nav > li > a {
        padding: 0.35em 0.5em;
        margin:0 15px;
        font-size:16px
    }
}
@media (min-width: 1200px) {
    .navbar-default .navbar-nav > li > a {
        margin:0 20px;
    }
}