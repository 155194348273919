.alerts {
    h1{
        color:white;
        margin:3em 0;
    }
    h3{
        text-align:left;
    }
    .promobox{
        padding: 0 0 1em;
        border-bottom:1px solid $cbre-mid-grey;
    }
    .btn {
        margin: 0 0 1em;
    }
    section .img-responsive {
        margin-right:auto;
        margin-left:auto;
    }
    .hero-unit{
        margin: 55px 0 2em;
        padding:0;
        background:url("/assets/img/maco_americas_chicago.jpg") no-repeat 0;
        background-size:cover;
    }
    @media only screen and (max-width: 767px) {
        section .container{
            width:95%;
        }
    }
}   
