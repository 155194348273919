$cbre-green: #006A4D;
$cbre-green-back: #006A4D;
$dark-cbre-green: #004B35;
$cbre-vlight-green: #00A657;
$cbre-link: #69BE2B;
$cbre-white: #fbfeff;
$cbre-vwhite: #fcfcfd;
$cbre-blue: #00B2DD;
$cbre-dark-grey: #3A3A3A;
$cbre-dark-blue-gray: #3A3A3A;
$cbre-vdark-grey: #3A3A3A;
$cbre-grey: #DBD5BF;
$cbre-grey-blue: #DBD5BF;
$cbre-mid-grey: #E7E3D5;
$cbre-lightmid-grey: #E7E3D5;
$cbre-light-grey: #F7F5F1;
$cbre-vlight-grey: #F7F5F1;
$cbre-text-grey: #3A3A3A;
$cbre-text-darkgrey: #231F20; 
:root {
    font-size: 16px;
}
html, body {
    height: 100%;
    width: 100%;
} 
body {
    font-family: futura-pt,kozuka-gothic-pro,source-han-sans-traditional,"Trebuchet MS",Arial,sans-serif;
    line-height: 1.5;
    background: transparent;
    font-size: 16px; 
    color:$cbre-text-darkgrey;
    overflow: visible;
    height: 100%;
}
.hero-unit {
    margin: 4em 0 0;
    text-align: center;
}
.inner-section{
    margin: 0 1.5em 5em;
    text-align: center;
}
.common-section{
    margin: 5em 0;
    text-align: center;
}
a{
    color: $cbre-green;
    text-decoration: none;
}
a:hover, a:focus {
    color: $dark-cbre-green;
    cursor:pointer;
    text-decoration:none;
    transition: color, ease-in, ease-out .4s;
}
p {
    margin: 0 0 1em;
    letter-spacing: 0.25px;
    font-weight: 400;
}
ul li{
    margin: 0 0 0.75em;
}
ol li{
    margin: 0 0 1em;
}
h1{
    font-family: futura-pt,kozuka-gothic-pro,source-han-sans-traditional,"Trebuchet MS",Arial,sans-serif;
    font-weight:300;
    line-height: 1.3;
    color: $cbre-dark-grey;
    margin-bottom: 1em;
}
h1 span{
    color:$cbre-green;
}
section.hero-unit h1{
    font-weight:500;
    font-size:32px;
    text-transform: uppercase;
    color:white;
}
h2{
    font-family: futura-pt,kozuka-gothic-pro,source-han-sans-traditional,"Trebuchet MS",Arial,sans-serif;
    font-weight: 700;
    line-height: 1.4;
    color: $cbre-dark-grey;
    letter-spacing: 0px;
    font-size: 26px;
}
h3{
    font-family: futura-pt,kozuka-gothic-pro,source-han-sans-traditional,"Trebuchet MS",Arial,sans-serif;
    font-weight: 600;
    line-height: 1.4;
    color: $cbre-text-darkgrey;
    letter-spacing: 0px;
    font-size: 20px;
}
h4{
    font-family: futura-pt,kozuka-gothic-pro,source-han-sans-traditional,"Trebuchet MS",Arial,sans-serif;
    font-weight:500;
    font-size:18px;
    color: $cbre-text-darkgrey;
    line-height: 1.4;
}
h5{
    font-family: futura-pt,kozuka-gothic-pro,source-han-sans-traditional,"Trebuchet MS",Arial,sans-serif;
    font-weight:200;
    font-size:18px;
    color: $cbre-text-darkgrey;
    line-height: 1.4;
}
.linkicon{
    width:17px;
    margin-left:0.5em;
    top:-1px;
    position:relative;
}
/* End page content */
/* Buttons */
.btn {
    font-size: 14px;
    font-weight:600;
    padding: 0.75em 0.75em;
    width: auto;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius:0;
    margin:0 1em 1em;
}
.btn-default {
    color: white;
    background-color: $cbre-green;
    border-color: $cbre-green;
    letter-spacing: 1px;
}
.btn-default:hover {
    color: white;
    background-color: $cbre-link;
    border-color: $cbre-link; 
    transition: background, color, ease-in, ease-out .4s;
}
.btn-default-alt {
    color: $cbre-blue;
    background-color: transparent;
    border-color: $cbre-blue;
    letter-spacing: 1px;
}
.btn-default-alt:hover {
    color: white;
    background-color: $cbre-blue;
    border-color: $cbre-blue;
    transition: background, color, ease-in, ease-out .4s;
}
.btn-default-white {color: $cbre-green; background-color: white; border-color: white}
.btn-default-white:hover {color: white; background-color: $cbre-link; border-color: $cbre-link;
    transition: background, color, ease-in, ease-out .4s;}
/* Promoboxes */
section.wire-box{
    margin: 1em;
    border: 1px solid black;
    padding: 1em;
    text-align: center;
}
div.wire-box {
    margin: 5em 0 0;
    border: 1px solid black;
    padding: 1em;
    text-align: center;
}
.wire-box-duel{
    margin: 2rem 1em;
    border: 1px solid black;
    padding: 1em;
    text-align: center;
}
section.wire-box h2, div.wire-box h2, .wire-box-duel h2{
    margin-top: -1.4em;
    margin-bottom: 1.4em;
    background-color: white;
    width: auto;
}
/* Forms */
form input {
    box-shadow: none;
    margin: 0 auto 1em;
    height: auto;
    padding: 1em;
}
.form-control{
    border: 1px solid $cbre-mid-grey;
    padding: 1.5em 1em;
    font-size: 16px;
}
.alert-danger {
    background-color: transparent;
    border-color: red;
    color: red;
}
.alert-danger p {
    color: red;
}
/* End buttons */

/*Media queries*/ 
@media only screen and (min-width: 480px) {
    .common-section {
        margin: 3em 0 3em;
    }
    .inner-section {
        margin: 0 1.5em 7em;
    }
}
@media only screen and (min-width: 600px) {
    .common-section {
        margin: 7em 0 5em;
    }
    .inner-section {
        margin: 0 1.5em 9em;
    }
}
@media only screen and (min-width: 768px) {
    .container{
        width: 768px;
    }
}
@media (min-width: 992px) {
    .hero-unit {
        margin: 7em 0 0;
    }
    .common-section {
        margin: 9em 0 6em;
    }
    .inner-section {
        margin: 0 0 11em;
    }
    .container{
        width: 992px;
    }
    section.wire-box {
        width: 60%;
        margin: 0 auto;
    }
}
@media only screen and (min-width: 992px) {
    .wire-box-duel{
        min-height:240px
    }
}
@media only screen and (min-width: 1024px) {
    h1{
        font-size:40px
    }
    p.narrow{
        width:60%;
        margin:0 auto 1em;
    }
    .container{
        width: 1024px;
    }
}
@media only screen and (min-width: 1200px) {
    .hero-unit h1 {
        margin: 0 auto 0.5em;
    }
    h1{
        font-size:48px
    }
    .container{
        width: 1200px;
    }
}