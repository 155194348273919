.home {
    section .container{
        width:95%;
    }
    h2,h3{
        text-align: center;
    }
    section .img-responsive {
        margin-right:auto;
        margin-left:auto;
    }
    .hero-unit{
        margin: 55px 0 0;
        padding:0;
        background:url("/assets/img/maco_americas_chicago.jpg") no-repeat 0;
        background-size:cover;
    }
    .hero-unit .col-xs-12{
        padding: 3em 1em;
    }
    .hero-unit h1{
        margin: 0 0 1em;
        color: white;
        font-weight: 500;
        font-size: 30px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    .hero-unit p{
        text-align: center;
        color: white;
        font-weight: 500;
        letter-spacing: 1px;
    }
    .hero-unit img.main{
        text-align: center;
    }
    section.image-promo{
        margin: 3em 0;
    }
    section.image-promo .img-responsive {
        margin-bottom:2.5em;
    }
    section.image-promo p {
        text-align:center;
    }
    .help_section{
        background:$cbre-grey;
    }
    .promolink_section{
        background:$cbre-light-grey;
        padding: 3em 0;
        margin-bottom: 5em;
        text-align: center;
    }
    .promolink_section h2{
        margin-bottom: 1.5em;
    }
    .promolink_section h3{
        margin: 0.5em 0;
    }
    .promolink_section p{
        margin: 0 0 3em;
        max-width:99%;
    }
    .promolink_section .bg-box-shadow {
        margin:1em 0 4em;
    }
    .promolink_section .bg-box-shadow p {
        margin: 0 0 1em;
    }
    .promolink_section .bg-box-shadow p.lighttext{
        margin:1em 0 0;
        color:$cbre-mid-grey;
    }
    .promolink_section .img-responsive {
        margin: 1em 0 2em;
    }
    .customers-slim {
        background-color: $cbre-vwhite;
        padding: 2em;
        position: relative;
        top: -1.2em;
    }
    .features{
        text-align: center;
        margin:1em 0 3em;
    }
    .features h2.title {
        margin: 0 0 0.5em;
    }
    .features h3.introhead {
        margin: 0 0 2em;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.4;
    }
    .features .row {
        margin:2em 0 0;
    }
    .features .row h3 {
        margin:0.5em 0;
    }
    @media (min-width: 480px)    {
        section .img-responsive {
            width:70%
        }
        section .bg-box-shadow .img-responsive {
            padding: 0 0 1em;
            margin: 1em auto;
        }
        section.hero-unit .img-responsive{
            width:90%;
        }
    }
    @media (min-width: 600px) {
        section.hero-unit .btn {
            width: auto;
            margin-left:0.5em;
        }
        section.hero-unit .img-responsive {
            margin-top: 1.5em;
        }
        section.image-promo p {
            font-size: 1.1rem;
            font-weight: 400;
        }
    }
    @media (min-width: 667px)    {
        .btn {
            width: auto;
        }
    }
    @media (min-width: 768px) {
        .hero-unit p {
            margin-bottom: 1.5em;
            font-size: 18px;
        }
        .hero-unit .btn {
            width:auto;
        }
        .btn-watch {
            margin: 1em 0;
        }
        .col-sm-offset-1 {
            clear: both;
        }
        .customers {
            margin: 9em 0;
        }
        .customers-slim {
            top: -2.8em;
        }
        section .bg-box-shadow .img-responsive {
            margin: 0 auto;
            width:80%
        }
        .features .row {
            margin: 4em 0 0;
        }
        .features .introtext {
            width: 90%;
            margin: 0 auto;
        }
        section.features .img-responsive {
            width: 100%;
        }
        .bg-box-shadow .img-responsive {
            padding: 2em 1em;
            width: 100%;
        }
        section.image-promo .img-responsive{
            width: 95%;
            margin: 1rem 0 3rem;
        }
        section.image-promo p {
            text-align: left;
            margin: 3rem 0 0;
            font-size: 1.15rem;
        }
    }
    @media (min-width: 992px) {
        .hero-unit {
            margin: 0;
            padding: 4em 0 0;
            background-position: 0;
        }
        .hero-unit .col-xs-12 {
            padding: 4em 1em;
        }
        .hero-unit p {
            margin: 0 auto 2em;
            width: 80%;
        }
        section.hero-unit .img-responsive {
            width: 70%;
            margin-top: 1em;
        }
        section.features .img-responsive {
            width: 80%;
            margin-bottom: 4em;
        }
        .features .row {
            margin: 0;
        }
        .features .introtext {
            margin: 0 auto 4em;
        }
        section .img-responsive {
            width: 55%;
        }
        .promolink_section .promobox{
            min-height: 350px;   
        }
        .promolink_section .bg-box-shadow {
            margin:3em 0 6em;
        }
        section.image-promo .img-responsive {
            width: 100%;
        }
        section.image-promo p {
            width: 80%;
            margin: 4rem auto 0;
            font-size: 1.2rem;
        }
    }
    @media (min-width: 1100px) {
        section.image-promo .img-responsive {
            width: 90%;
            margin: 3rem auto;
        }
        section.image-promo p {
            margin: 8rem auto 0;
            width: 70%;
            float:left;
        }
    }
    @media (min-width: 1200px) {
        .hero-unit h1 {
            margin: 0 0 0.75em;
        }
        h2 {
            font-size: 28px;
        }
        section.hero-unit .img-responsive {
            width: 50%;
        }
        .col-sm-offset-1 {
            clear: none; 
        }
    }
}   
