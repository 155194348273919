footer {
    width: 100%;
    padding: 3.75em 0 2em;
    color: white;
    background: #006A4D;
    margin: 5em 0 0;
    letter-spacing: 2px;
    font-weight: 300;
    font-size: 14px;
}
footer img {
    margin: 0 0 3.75em 0;
}
footer ul {
    margin: 0;
    padding: 0;
}
footer ul.block2 {
    margin: 2em 0 0;
}
footer ul li {
    display: block;
    margin: 0 0 1em;
}
footer a {
    color: white;
}
footer a:hover {
    color: $cbre-link;
}
footer ul.block2 li{ 
    border-top: 1px solid white;
    margin: 1em 0 0;
    padding-top: 1em;
}
footer ul.block2 li.first{ 
    border-top: none;
}
footer ul.block2 a {
    color: $cbre-link;
}
footer ul.block2 a:hover {
    color: white;
}
.footnote{
    margin-top:3em;
}
@media only screen and (min-width: 360px) {

}
@media (min-width: 768px){

}
@media (min-width: 992px){
    footer {
        padding: 4em 0 2em;
    }
    footer ul li {
        float:left;
        margin: 0 2em 0 0;
    }
    footer ul.block1 {
        border-bottom: 1px solid #17794b;
        margin-bottom:2em;
        padding-bottom: 3em;
    }
    footer ul.block2 {
        margin: 0 0 3em;
    }
    footer ul.block2 li {
        border-top:none;
        margin: 0 1.5em 0 0;
        padding:0;
    }
    .footnote {
        clear: both;
        padding-top: 3em;
    }
}